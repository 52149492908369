.curve-img {
  span {
    height: 100% !important;
  }
}

// prevent zendesk widget overlap with mobile menu
iframe#launcher {
  z-index: 40 !important;
}

.smartform-warning {
  color: #f25153;
  background-color: #fcf2f2;
}

// Text Outline
.custom-title-text-outline {
  text-shadow:
    -1px 1px 0 #1f2323,
    1px 1px 0 #1f2323,
    1px -1px 0 #1f2323,
    -1px -1px 0 #1f2323;
}


/* start - custom radio */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-radio.small-version {
  font-size: 14px;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
  border:1px solid #1f2323
}

.custom-radio.small-version .checkmark {
  top: 2px;
}

.custom-radio input:checked ~ .checkmark {
  background-color: #ffffff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

.custom-radio .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #1f2323;
}
@media screen and (max-width: 1024px) {
  .custom-radio.small-version {
    font-size: 12px;
  }

  .custom-radio.small-version .checkmark {
    top: 0px;
  }
}
@media screen and (max-width: 480px) {
  .custom-radio {
    padding-left: 20px;
    font-size: 14px;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 14px;
    width: 14px;
  }
  .custom-radio .checkmark:after {
    width: 7px;
    height: 7px;
  }
}
/* end - custom radio */

/* start - policies content */
.policies-content {
  --tw-text-opacity: 1;
  color: rgb(31 35 35 / var(--tw-text-opacity)); // text-gray-700
}

.policies-content .cta-button {
  font-weight: 700;
  text-align: center;
  border-radius: 0.375rem;
  display: inline-block;
  padding: 1rem;
  color: white;
  background-color: rgb(31 35 35);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.2s;
}

.policies-content .cta-button:hover {
  opacity: 0.9;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.policies-content h1 {
  margin: 1.25rem 0 0.5rem 0;
  font-size: 1.875rem;
  line-height: 2.25rem;
  width: 100%;
  font-weight: 700;
  text-align: center;
}

.policies-content h2 {
  margin: 2.5rem 0 0.5rem 0;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.policies-content h3 {
  margin-top: 1rem;
  text-align: left;
  font-style: italic;
  font-weight: 600;
}

.policies-content p {
  margin-bottom: 0.75rem;
}

.policies-content a {
  --tw-text-opacity: 1;
  color: rgb(43 65 227 / var(--tw-text-opacity)); // text-blue-500
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.policies-content table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border-width: 1px;
  border-color: rgb(55 65 81);
}

.policies-content thead {
  width: 100%;
}

.policies-content th {
  border-width: 1px;
  border-color: rgb(55 65 81);
  padding: 0.5rem;
}

.policies-content td {
  border-width: 1px;
  border-color: rgb(55 65 81);
  padding: 0.5rem;
}

.policies-content ul {
  margin-left: 1rem;
  list-style-type: disc;
}

.hide-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

@media not all and (min-width: 768px) {
  .policies-content h1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
/* end - policies content */
