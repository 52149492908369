/* stylelint-disable scss/at-rule-no-unknown */
@import './spinner';
@import './custom';
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
.custom-scrollbar {
  scrollbar-width: 7px;
  scrollbar-color: #1f2323 #eeeeee;
}

/* Chrome, Edge, and Safari */
.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #1f2323;
  border-radius: 14px;
  border: 3px solid #1f2323;
}

@media screen and (max-width: 480px) {
  /* Firefox */
  .custom-scrollbar {
    scrollbar-width: 4px;
  }

  /* Chrome, Edge, and Safari */
  .custom-scrollbar::-webkit-scrollbar {
    width: 4px;
  }
}

/* Firefox */
.custom-scrollbar-thin-light {
  scrollbar-width: 5px;
  scrollbar-color: #a5a5a5 #eeeeee;
}

/* Chrome, Edge, and Safari */
.custom-scrollbar-thin-light::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scrollbar-thin-light::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 5px;
}

.custom-scrollbar-thin-light::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 14px;
  border: 3px solid #a5a5a5;
}

/* Firefox */
.custom-scrollbar-thin-dark {
  scrollbar-width: 5px;
  scrollbar-color: #1c3135 #eeeeee;
}

/* Chrome, Edge, and Safari */
.custom-scrollbar-thin-dark::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scrollbar-thin-dark::-webkit-scrollbar-track {
  background: #1c3135;
  border-radius: 5px;
}

.custom-scrollbar-thin-dark::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 14px;
  border: 3px solid #a5a5a5;
}

@media screen and (max-width: 480px) {
  /* Firefox */
  .custom-scrollbar-thin-dark, .custom-scrollbar-thin-light {
    scrollbar-width: 3px;
  }

  /* Chrome, Edge, and Safari */
  .custom-scrollbar-thin-dark::-webkit-scrollbar, .custom-scrollbar-thin-light::-webkit-scrollbar {
    width: 3px;
  }
}

@layer components {
  // Remove arrow in input number on Firefox
  input[type='number'] {
    appearance: textfield;
  }

  // Remove arrow in input number on Chrome, Safari
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  .transform-wiggle {
    transition: transform 0.5s cubic-bezier(0.68, -0.56, 0.35, 1.5);
  }

  .reebelo-container {
    @apply mx-auto px-5 sm:px-8 lg:container;
  }

  .menu-item {
    & > .submenu {
      position: absolute;
    }

    &:hover > .submenu {
      display: flex;
    }
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    .main-menu {
      font-size: 0.8rem !important;
    }
  }

  .poppable {
    @apply invisible opacity-0 transition-opacity duration-200 ease-in-out;
  }

  .poppable[data-show] {
    @apply visible opacity-100;
  }

  *[data-open] .menu-backdrop {
    @apply visible opacity-100;
  }

  .btn-close {
    // Stolen from bootstrap
    box-sizing: content-box !important;
    width: 1em !important;
    height: 1em !important;
    padding: 0.25em !important;
    color: #000 !important;
    background: transparent
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
      50%/1em auto no-repeat !important;
    border: 0 !important;
    border-radius: 0.25rem !important;
    opacity: 1 !important;
  }

  .menu-slider *[data-show] {
    left: 0;
  }

  // footer accordion
  .accordion {
    [data-toggle='collapse'][data-open] {
      & + .collapse-icon {
        @apply rotate-180;
      }
    }

    .shown {
      max-height: 200px; // has to be greater than the height of the content
    }
  }

  .content-empty {
    &::before {
      content: '';
    }
  }
}
