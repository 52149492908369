// For content, we use"Modern Era"
// "Avenir Next" seem to be deprecated
// "Acumin Pro Wide" is for bold title for headers

@font-face {
  font-family: "Modern Era";
  src: url("/fonts/ModernEra-Black.eot?#iefix") format("embedded-opentype"), url("/fonts/ModernEra-Black.woff2") format("woff2"), url("/fonts/ModernEra-Black.woff") format("woff"), url("/fonts/ModernEra-Black.ttf") format("truetype"), url("/fonts/ModernEra-Black.svg#ModernEra-Black") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0000 to U+007F;
}

@font-face {
  font-family: "Modern Era";
  src: url("/fonts/ModernEra-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/ModernEra-Regular.woff2") format("woff2"), url("/fonts/ModernEra-Regular.woff") format("woff"), url("/fonts/ModernEra-Regular.ttf") format("truetype"), url("/fonts/ModernEra-Regular.svg#ModernEra-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0000 to U+007F;
}

@font-face {
  font-family: "Acumin Pro Wide";
  src: url("/fonts/AcuminProWide-UltraBlack.eot?#iefix") format("embedded-opentype"), url("/fonts/AcuminProWide-UltraBlack.woff2") format("woff2"), url("/fonts/AcuminProWide-UltraBlack.woff") format("woff"), url("/fonts/AcuminProWide-UltraBlack.ttf") format("truetype"), url("/fonts/AcuminProWide-UltraBlack.svg#AcuminProWide-UltraBlack") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: fallback;
  unicode-range: U+0000 to U+007F;
}
